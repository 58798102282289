import { GatsbyImage } from "gatsby-plugin-image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import uuid4 from "uuid4";

const Supportedby = ({
	title,
	supportbyImages,
	sponsorTitle,
	sponsorLogos,
	awardsTitle,
	awardsImages,
}) => {
	return (
		<section className="py-5">
			<Container>
				{awardsImages && awardsImages.length > 0 && (
					<Row className="pb-5">
						<Col>
							<h2 className="text-primary">
								{awardsTitle ?? "Awards and nominations"}
							</h2>
						</Col>
					</Row>
				)}
				{awardsImages && awardsImages.length > 0 && (
					<Row className="  gap-y-6">
						{awardsImages.map((item, i) => (
							<Col xs={6} md={4} lg={2} key={uuid4()} className="mb-4 mb-md-0">
								<GatsbyImage
									className="w-90 "
									image={item?.localFile.childImageSharp.gatsbyImageData}
									alt={item?.altText}
								/>
							</Col>
						))}
					</Row>
				)}
				<Row className="py-5">
					<Col>
						<h2 className="text-primary">
							{title ?? "Holding Space is proudly supported by"}
						</h2>
					</Col>
				</Row>
				{supportbyImages && supportbyImages.length > 0 && (
					<Row className="justify-content-center align-items-center text-lg-center gap-y-6">
						{supportbyImages.map((item, i) => (
							<Col
								xs={6}
								md={4}
								lg={i == 2 || i == 3 ? 3 : 2}
								key={uuid4()}
								className="mb-4 mb-md-0"
							>
								<GatsbyImage
									image={item?.localFile.childImageSharp.gatsbyImageData}
									alt={item?.altText}
								/>
							</Col>
						))}
					</Row>
				)}

				<Row className="py-5">
					<Col>
						<h2 className="text-primary">
							{sponsorTitle ?? "Thank you to our sponsors"}
						</h2>
					</Col>
				</Row>
				{sponsorLogos && sponsorLogos.length > 0 && (
					<Row className="align-items-center text-lg-center">
						{sponsorLogos.map((item) => (
							<Col xs={6} md={4} lg={3} key={uuid4()} className="mb-4 mb-md-0">
								<a
									href={item.link?.url}
									rel="noreferrer"
									target={item.link?.target ?? "_blank"}
								>
									<GatsbyImage
										image={
											item?.image.node?.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={item?.image.node?.altText}
										style={{ maxWidth: "240px", maxHeight: "230px" }}
										objectFit="contain"
									/>
								</a>
							</Col>
						))}
					</Row>
				)}
			</Container>
		</section>
	);
};

export default Supportedby;
