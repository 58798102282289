import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Supportedby from "../components/supportedby";
import Layout from "../components/layout";
import { checkPropertiesForNull } from "../utils/objectHelper";
import { SafeHtmlParser } from "../components/safeHtmlParser";

const AboutUsPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				pageData: wpPage(slug: { eq: "about-us" }) {
					seoFields {
						title
						metaDescription
						opengraphTitle
						opengraphDescription
						localBusinessSchema
						image {
							node {
								sourceUrl
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
										original {
											width
											height
										}
									}
								}
							}
						}
					}
					aboutPageFieldGroups {
						missionSection {
							heading
							content
						}
						howCanWeHelpSection {
							heading
							content
							cta {
								title
								target
								url
							}
						}
						longContent
						aboutVision {
							heading
							content
							bottomText
						}
						supportSection {
							heading
							imageGallery {
								nodes {
									altText
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
											original {
												width
												height
											}
										}
									}
								}
							}
						}
						sponsorsSection {
							heading
							sponsorItems {
								image {
									node {
										altText
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: [AUTO, WEBP]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: CONTAIN }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
												original {
													width
													height
												}
											}
										}
									}
								}
								link {
									target
									title
									url
								}
							}
						}
					}
				}
			}
		`
	);

	const {
		pageData: { seoFields, aboutPageFieldGroups },
	} = data;

	const {
		missionSection,
		howCanWeHelpSection,
		longContent,
		aboutVision,
		supportSection,
		sponsorsSection,
	} = aboutPageFieldGroups;

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: `${siteUrl}`,
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "About",
				item: `${siteUrl}/about-us`,
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/about-us`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.sourceUrl}`,
							width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
							alt: "About Holding Space",
						},
					],
				}}
			/>

			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h1 className="text-primary">About Holding Space</h1>
						</Col>
					</Row>
				</Container>
			</section>
			{aboutVision && !checkPropertiesForNull(aboutVision, ["heading"]) && (
				<section>
					<Container>
						<Row>
							<Col>
								<h2 className="text-primary">{aboutVision?.heading}</h2>
							</Col>
						</Row>
						<Row>
							<Col>
								<SafeHtmlParser htmlContent={aboutVision?.content} />
							</Col>
						</Row>
					</Container>
				</section>
			)}
			{missionSection &&
				!checkPropertiesForNull(missionSection, ["heading"]) && (
					<section className="py-5">
						<Container>
							<Row>
								<Col>
									<h2 className="text-primary">{missionSection?.heading}</h2>
								</Col>
							</Row>
							<Row>
								<Col>
									<SafeHtmlParser htmlContent={missionSection?.content} />
								</Col>
							</Row>
						</Container>
					</section>
				)}
			{longContent && <SafeHtmlParser htmlContent={longContent} />}
			{howCanWeHelpSection &&
				!checkPropertiesForNull(howCanWeHelpSection, ["heading"]) && (
					<section className="pt-5">
						<Container>
							<Row>
								<Col>
									<h2 className="text-primary">
										{howCanWeHelpSection?.heading}
									</h2>
								</Col>
							</Row>
							<Row>
								<Col>
									<SafeHtmlParser htmlContent={howCanWeHelpSection?.content} />
								</Col>
							</Row>
							{howCanWeHelpSection.cta && howCanWeHelpSection.cta.url && (
								<Row>
									<Col lg={4}>
										<Button
											as={Link}
											to={howCanWeHelpSection.cta?.url}
											variant="info"
											className="full-width-button"
											size="lg"
											target={howCanWeHelpSection.cta?.target ?? "_blank"}
										>
											{howCanWeHelpSection.cta?.title}
										</Button>
									</Col>
								</Row>
							)}{" "}
							<Row>
								<Col>
									<h3 className="text-primary pt-4 text-center">
										{aboutVision?.bottomText}
									</h3>
								</Col>
							</Row>
						</Container>
					</section>
				)}
			<Supportedby
				title={supportSection?.heading}
				supportbyImages={supportSection?.imageGallery?.nodes}
				sponsorTitle={sponsorsSection?.heading}
				sponsorLogos={sponsorsSection?.sponsorItems}
			/>
		</Layout>
	);
};
export default AboutUsPage;
